<template>
  <div>
    <v-form fast-fail style="padding: 0 10px;">
      <v-rating
        v-model="NumberStars"
        bg-color="orange-lighten-1"
        color="blue"
      ></v-rating>

      <v-text-field v-model="Note" :label="$t('Note')"></v-text-field>
      <v-btn
        type="submit"
        color="primary"
        block
        class="mt-2"
        @click="sendreview()"
        >{{ $t("review") }}</v-btn
      >
    </v-form>
  </div>
</template>

<script>
import { review } from "../../../../api/reservation";
export default {
  data() {
    return {
      NumberStars: 0,
      Note: null,
      IdInit: 0,
    };
  },
  props: {
    NumberStarsInit: {
      type: Number,
    },
    NoteInit: {
      type: String,
    },
    id: {
      type: Number,
    },
  },
  created() {
    this.Note = this.NoteInit;
    this.NumberStars = this.NumberStarsInit;
  },
  methods: {
    sendreview() {
      let data = {
        review_stars: this.NumberStars,
        review_note: this.Note,
      };
      review(data, this.id).then((res) => {
        if (res.data) {
          this.$emit("Closed", [true]);
        }
      });
    },
  },
};
</script>

<style></style>
