import axios from 'axios';

async function index(data = {}) {
    return await new Promise((resolve, reject) => {
        axios
            .get(`reservations`, { params: data })
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            })
    })
}

async function reserve(data = {}) {
    return await new Promise((resolve, reject) => {
        axios
            .post(`reserve`, data)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    })
}

async function review(data,id){
    return await axios.put(`reservation/review/${id}`,data)
    .then(res=> res)
    .catch(error=>error.data)
}

function applyCoupon(company_id, coupon) {
    return axios({
        method:'get',
        url:`discount-coupons/apply?company_id=${company_id}&coupon=${coupon}`,
        baseURL: process.env.VUE_APP_DASHBOARD_API,
    })
    .then(res => {
        return res && res.data ? res.data : null;
    });
}

export {
    index,
    reserve,
    review,
    applyCoupon
}