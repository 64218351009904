<template>
  <v-card v-bind="cardProps">
    <v-toolbar v-bind="cardToolbarProps">
      {{ cardTitle }}
    </v-toolbar>
    <v-card-text v-bind="cardTextProps">
      <v-row no-gutters>
        <v-col cols="12">
          <p>
              {{ $t('RentalCost') }}: <strong> {{ calculateItemCost(item.total_cost, item) }} </strong>
          </p>
          <p>
            {{ $t("Tax") }}: <strong> {{ calculateItemCost(item.tax_amount, item) }} </strong>
          </p>
          <p>
            {{ $t("Discount") }}: <strong> {{ item.discount }}% </strong>
          </p>
          <v-divider></v-divider>
          {{ $t("TotalCost") }}:
          <strong> {{ calculateItemCost(item.amount_after_tax, item) }} </strong>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-bind="cardActionsProps">
      <slot name="card-actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from "vue";
import i18n from "../../../../i18n/i18n";

export default {
  name: "ReceivingDeliveringAddresses",
  props: {
    item: {
      type: Object,
      default: (_) => {
        return {
          from: null,
          receiving_time: null,
          receiving_address: null,
          to: null,
          sending_time: null,
          sending_address: null,
        };
      },
    },
    cardTitle: {
      type: String,
      default: i18n.t("CostDetails"),
    },
    cardProps: {
      type: Object,
      default: (_) => {
        return {};
      },
    },
    cardTextProps: {
      type: Object,
      default: (_) => {
        return {
          class: "pa-5 text-h6",
        };
      },
    },
    cardToolbarProps: {
      type: Object,
      default: (_) => {
        return {
          color: "dark",
          dark: true,
          class: "text-h6",
        };
      },
    },
    cardActionsProps: {
      type: Object,
      default: (_) => {
        return {
          class: "justify-end",
        };
      },
    },
  },
  methods: {
    calculateItemCost(amount, item) {
      let companyUSDCurrency = item.car.company.currencies.filter(
        (currency) => currency.iso == "USD"
      )[0];

      let itemCurrencyID = companyUSDCurrency.id;
      let itemExchangeRate = companyUSDCurrency.exchange_rate.rate;

      if (item.currency) {
        itemCurrencyID = item.currency.id;
        itemExchangeRate = item.exchange_rate;
      }

      const cost = Vue.currencyHelper.convert(
        amount,
        itemExchangeRate,
        itemCurrencyID,
        companyUSDCurrency.exchange_rate.rate,
        companyUSDCurrency.id
      );

      return cost.toFixed(2) + " $";
    },
  },
};
</script>