<template>
  <v-card v-bind="cardProps">
    <v-toolbar v-bind="cardToolbarProps">
      {{ cardTitle }}
    </v-toolbar>
    <v-card-text v-bind="cardTextProps">
      <v-row no-gutters>
        <v-col cols="12" sm="6">
          <div class="text-center">
            <h4><v-icon>mdi-call-made</v-icon> {{ $t("Receiving") }}</h4>
            <v-divider></v-divider>
          </div>
          <div class="pa-2">
            <p class="mb-0">
              <v-icon>mdi-clock</v-icon>
              {{ item.from }} / {{ item.receiving_time }}
            </p>
            <p class="text-justify">
              <v-icon>mdi-map-marker</v-icon>
              {{ item.receiving_address }}
            </p>
          </div>
        </v-col>
        <v-divider vertical class="d-none d-sm-block"></v-divider>
        <v-col cols="12" sm="6">
          <div class="text-center">
            <h4><v-icon>mdi-call-received</v-icon> {{ $t("Delivering") }}</h4>
            <v-divider></v-divider>
          </div>
          <div class="pa-2">
            <p class="mb-0">
              <v-icon>mdi-clock</v-icon>
              {{ item.to }} / {{ item.sending_time }}
            </p>
            <p>
              <v-icon>mdi-map-marker</v-icon>
              {{ item.sending_address }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-bind="cardActionsProps">
      <slot name="card-actions" />
    </v-card-actions>
  </v-card>
</template>


<script>
import i18n from "../../../../i18n/i18n";

export default {
  name: "ReceivingDeliveringAddresses",
  props: {
    item: {
      type: Object,
      default: (_) => {
          return {
              from: null,
              receiving_time: null,
              receiving_address: null,
              to: null,
              sending_time: null,
              sending_address: null,
          }
      },
    },
    cardTitle: {
      type: String,
      default: i18n.t("ReservationAddress"),
    },
    cardProps: {
      type: Object,
      default: (_) => {
        return {};
      },
    },
    cardTextProps: {
      type: Object,
      default: (_) => {
          return {
              class: "pa-5 text-h6",
          }
      },
    },
    cardToolbarProps: {
      type: Object,
      default: (_) => {
          return {
              color: "dark",
              dark: true,
              class: "text-h6",
          }
      },
    },
    cardActionsProps: {
      type: Object,
      default: (_) => {
          return {
              class: "justify-end",
          }
      },
    },
  },
};
</script>