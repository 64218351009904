<template>
  <v-data-table
    :style="styles"
    :headers="headers"
    :items="reservations"
    :loading="apiCallLoading"
    :items-per-page="10"
    class="rounded-0"
    :no-data-text="$t('NoData')"
    :loading-text="$t('LoadingData')"
    :header-props="headerProps"
    :custom-sort="customSort"
    :footer-props="{
      itemsPerPageAllText: $t('All'),
      itemsPerPageText: $t('RowsPerPage'),
      pageText: '{0}-{1} ' + $t('Of') + ' {2}',
      showCurrentPage: true,
      showFirstLastPage: true,
    }"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title class="text-h5 font-weight-bold">
          {{ $tc("Reservation", 2) }}
        </v-toolbar-title>
      </v-toolbar>
    </template>
    <template v-slot:item.car="{ item }">
      <div>
        {{ item.car.brand.name }}
        {{ item.car.model.name }}
      </div>
    </template>
    <template v-slot:item.date="{ item }">
      <div>
        <v-icon>mdi-call-received</v-icon> {{ item.from }}
        -
        <v-icon>mdi-call-made</v-icon> {{ item.to }}
      </div>
    </template>
    <template v-slot:item.reservation="{ item }">
      <div>{{ item.id }} / {{ item.from }} / {{ item.total_cost }}</div>
    </template>
    <template v-slot:item.reservation_address="{ item }">
      <v-dialog max-width="600">
        <template v-slot:activator="{ on, attrs }">
          <a href="#" v-bind="attrs" v-on="on">{{ $t("ClickToView") }}</a>
        </template>
        <template v-slot:default="dialog">
          <receiving-delivering-addresses :item="item">
            <template v-slot:card-actions>
              <v-btn text @click="dialog.value = false">
                {{ $t("Close") }}
              </v-btn>
            </template>
          </receiving-delivering-addresses>
        </template>
      </v-dialog>
    </template>
    <template v-slot:item.cost_details="{ item }">
      <div>
        {{ $t("TotalCost") }}:
        <strong>{{ convertReservationCost(item) }}</strong> <br />
        <v-dialog max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <a href="#" v-bind="attrs" v-on="on">{{ $t("Details") }}</a>
          </template>
          <template v-slot:default="dialog">
            <cost-details :item="item">
              <template v-slot:card-actions>
                <v-btn text @click="dialog.value = false">
                  {{ $t("Close") }}
                </v-btn>
              </template>
            </cost-details>
          </template>
        </v-dialog>
      </div>
    </template>
    <template v-slot:item.status="{ item }">
      <v-chip :color="getStatusColor(item.status)" dark>
        {{ getStatus(item.status) }}
      </v-chip>
    </template>
    <template v-slot:item.review_stars ="{item}">
      <v-dialog max-width="600" v-if="item.status == 'done'">
        <template v-slot:activator="{ on, attrs }">
          <a href="#" style="font-weight: bold;" v-bind="attrs" v-on="on">
            {{ item.review_stars > 0 ? $t('UpdateReview')  : $t("ClickToReview")}}
          </a>
        </template>
        <template v-slot:default="dialog">
          <v-toolbar style="font-weight: bold; font-size: 35px;">
            {{ $t('review') }}
          </v-toolbar>
          <v-card>
            <review-dialog 
            :NumberStarsInit ="item.review_stars" 
            :NoteInit="item.review_note" 
            :id="item.id"
            @Closed="ClosedDialog"
            >
            </review-dialog>
            <v-card-actions>
              <v-btn color="primary" text @click="dialog.value = false" ref="ClosedDialog">{{ $t("Close") }}</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
      <p v-else>{{ $t('CannotReviewNow') }}</p>
    </template>
  </v-data-table>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { index } from "../../../api/reservation";
import ReceivingDeliveringAddresses from "./components/ReceivingDeliveringAddresses";
import CostDetails from "./components/CostDetails";
import ReviewDialog from './components/ReviewDialog.vue';

export default {
  name: "ReservationsDataTable",
  components: {
    ReceivingDeliveringAddresses,
    CostDetails,
    ReviewDialog,
  },
  props: {
    styles: {
      type: Object,
      default: (_) => {
        return {};
      },
    },
  },
  data() {
    return {
      headerProps: {
        sortByText: this.$tc('sortBy')
      },
      headers: [
        {
          text: this.$t("ReservationNumber"),
          value: "reservation",
        },
        { text: this.$tc("Vehicle", 1), value: "car"},
        {
          text: this.$tc("ReservationDate", 1),
          value: "date",
        },
        {
          text: this.$t("ReservationAddress"),
          value: "reservation_address",
          sortable: false 
        },
        {
          text: this.$t("TotalCost"),
          value: "cost_details",
        },
        {
          text: this.$t("Status"),
          value: "status",
        },
        {
          text: this.$t("review"),
          value: "review_stars",
        },
      ],
      apiCallLoading: false,
      reservations: [],
      pagination: {
        limit: -1,
        page: 1,
      },
    };
  },
  computed: {
    ...mapGetters({
      locale: "locale",
      statuses: "getStatuses",
    }),
  },
  methods: {
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
          let prevItem = ''
          let nextItem =''
          if(index == 'reservation') { prevItem = a.id ; nextItem = b.id }
          else if(index  == 'car'){prevItem = a.car.brand.name; nextItem = b.car.brand.name}
          else if(index == 'date'){prevItem= a.from ; nextItem=b.from} 
          else if (index == 'cost_details'){prevItem = this.convertReservationCost(a);nextItem=this.convertReservationCost(b)}
          else if(index == 'status'){prevItem= this.getStatus(a.status);nextItem=this.getStatus(b.status)}
          if (!isDesc) {
            return prevItem< nextItem ? -1 : 1;
          } else {
            return prevItem < nextItem ? -1 : 1;
          }
      });
      return items;
    },
    ClosedDialog(val){
      if(val[0]){
        this.$refs.ClosedDialog.$listeners.click()
      }
    },
    fetchReservations() {
      this.apiCallLoading = true;

      let data = {
        ...this.pagination,
      };

      index(data)
        .then((resp) => {
          this.reservations = resp.data.data;
        })
        .finally(() => {
          setTimeout(() => {
            this.apiCallLoading = false;
          }, 500);
        });
    },
    getStatusColor(status) {
      status = this.statuses.filter((st) => st.value == status)[0];
      if (status) return status.color;
      return "primary";
    },
    getStatus(status) {
      status = this.statuses.filter((st) => st.value == status)[0];
      if (status) return status.text;
      return this.$t("Pending");
    },
    convertReservationCost(item) {
      let companyUSDCurrency = item.car.company.currencies.filter(
        (currency) => currency.iso == "USD"
      )[0];

      let itemCurrencyID = companyUSDCurrency.id;
      let itemExchangeRate = companyUSDCurrency.exchange_rate.rate;

      if (item.currency) {
        itemCurrencyID = item.currency.id;
        itemExchangeRate = item.exchange_rate;
      }

      const cost = Vue.currencyHelper.convert(
        item.amount_after_tax,
        itemExchangeRate,
        itemCurrencyID,
        companyUSDCurrency.exchange_rate.rate,
        companyUSDCurrency.id
      );

      return cost.toFixed(2) + " $";
    },
  },
  mounted() {
    this.fetchReservations();
  },
};
</script>
<style>
.v-data-table__mobile-table-row{
  border: 2px solid gray !important;
  margin: 4px !important;
  padding: 2px !important;
  border-radius: 15px;
}
.theme--light.v-data-table {

  background-color: #EEF0EB
}
.v-data-footer {
  background-color: #EEF0EB;
}

</style>