var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"rounded-0",style:(_vm.styles),attrs:{"headers":_vm.headers,"items":_vm.reservations,"loading":_vm.apiCallLoading,"items-per-page":10,"no-data-text":_vm.$t('NoData'),"loading-text":_vm.$t('LoadingData'),"header-props":_vm.headerProps,"custom-sort":_vm.customSort,"footer-props":{
    itemsPerPageAllText: _vm.$t('All'),
    itemsPerPageText: _vm.$t('RowsPerPage'),
    pageText: '{0}-{1} ' + _vm.$t('Of') + ' {2}',
    showCurrentPage: true,
    showFirstLastPage: true,
  }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$tc("Reservation", 2))+" ")])],1)]},proxy:true},{key:"item.car",fn:function(ref){
  var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.car.brand.name)+" "+_vm._s(item.car.model.name)+" ")])]}},{key:"item.date",fn:function(ref){
  var item = ref.item;
return [_c('div',[_c('v-icon',[_vm._v("mdi-call-received")]),_vm._v(" "+_vm._s(item.from)+" - "),_c('v-icon',[_vm._v("mdi-call-made")]),_vm._v(" "+_vm._s(item.to)+" ")],1)]}},{key:"item.reservation",fn:function(ref){
  var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.id)+" / "+_vm._s(item.from)+" / "+_vm._s(item.total_cost))])]}},{key:"item.reservation_address",fn:function(ref){
  var item = ref.item;
return [_c('v-dialog',{attrs:{"max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"href":"#"}},'a',attrs,false),on),[_vm._v(_vm._s(_vm.$t("ClickToView")))])]}},{key:"default",fn:function(dialog){return [_c('receiving-delivering-addresses',{attrs:{"item":item},scopedSlots:_vm._u([{key:"card-actions",fn:function(){return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v(" "+_vm._s(_vm.$t("Close"))+" ")])]},proxy:true}],null,true)})]}}],null,true)})]}},{key:"item.cost_details",fn:function(ref){
  var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.$t("TotalCost"))+": "),_c('strong',[_vm._v(_vm._s(_vm.convertReservationCost(item)))]),_vm._v(" "),_c('br'),_c('v-dialog',{attrs:{"max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"href":"#"}},'a',attrs,false),on),[_vm._v(_vm._s(_vm.$t("Details")))])]}},{key:"default",fn:function(dialog){return [_c('cost-details',{attrs:{"item":item},scopedSlots:_vm._u([{key:"card-actions",fn:function(){return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v(" "+_vm._s(_vm.$t("Close"))+" ")])]},proxy:true}],null,true)})]}}],null,true)})],1)]}},{key:"item.status",fn:function(ref){
  var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"dark":""}},[_vm._v(" "+_vm._s(_vm.getStatus(item.status))+" ")])]}},{key:"item.review_stars",fn:function(ref){
  var item = ref.item;
return [(item.status == 'done')?_c('v-dialog',{attrs:{"max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticStyle:{"font-weight":"bold"},attrs:{"href":"#"}},'a',attrs,false),on),[_vm._v(" "+_vm._s(item.review_stars > 0 ? _vm.$t('UpdateReview') : _vm.$t("ClickToReview"))+" ")])]}},{key:"default",fn:function(dialog){return [_c('v-toolbar',{staticStyle:{"font-weight":"bold","font-size":"35px"}},[_vm._v(" "+_vm._s(_vm.$t('review'))+" ")]),_c('v-card',[_c('review-dialog',{attrs:{"NumberStarsInit":item.review_stars,"NoteInit":item.review_note,"id":item.id},on:{"Closed":_vm.ClosedDialog}}),_c('v-card-actions',[_c('v-btn',{ref:"ClosedDialog",attrs:{"color":"primary","text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v(_vm._s(_vm.$t("Close")))])],1)],1)]}}],null,true)}):_c('p',[_vm._v(_vm._s(_vm.$t('CannotReviewNow')))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }