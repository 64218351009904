<template>
  <div class="reservations-page">
    <v-container fluid fill-height class="pa-0">
      <v-row justify="center" align="center">
        <v-col cols="12" class="reservations-data-table">
          <reservations-data-table />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ReservationsDataTable from "./DataTable";
export default {
  name: "AccountReservations",
  components: {
    ReservationsDataTable,
  },
};
</script>

<style lang="scss" scoped>
.reservations-page {
  min-height: 80vh;
}
</style>
